import React from "react"
import { Row, Col } from "react-bootstrap"
import LandingPageForm from "../Common/LandingPageForm"
import {Link} from 'gatsby'


const LogistikexpertenIntro = () => {
  return (
    <section className="about-area bg-fafafb pt-4 pb-4 px-5" style={{ marginTop: "6%" }}>
      <div className="container-fluid">
        <Row className="align-items-center">
          <Col lg={8} md={12}>
            <div className="about2-content">
              <div className="content">
                <h1>Die Experten für Software in der Logistik</h1>
                <p className={"border-left pl-3 border-primary"}>
                  LOGENTIS entwickelt seit fast 16 Jahren <Link to={"/logistiksoftware/"}>Logistiksoftware</Link> für
                  verschiedene Bereiche wie Versand,
                  Warehouse Management und Materialflussoptimierung. Unsere Kunden haben große Logistikabteilungen,
                  operieren zumeist europeaweit und vertrauen auf die Expertise von LOGENTIS für Themen innerhalb
                  der Intra- und Transportlogistik.
                </p>
                <h2 className={"h4 text-primary"}>Warum uns Kunden für Logistikprojekte wählen</h2>
                <ul>
                  <li>Großes Know-How im Bereich Versandlogistik und Warehouse Management</li>
                  <li>Viele Jahre Erfahrung in Projekten der Intralogistik</li>
                  <li>Hauseigene Software mit steter technischer Weiterentwicklung gibt Zukunftssicherheit</li>
                  <li>Know-How in logistiknaher Hardware wie Scanner, Waagen oder Drucker</li>
                  <li>Partnerschaften mit Beratern für Detailkonzepte und Forecasts</li>
                </ul>
              </div>
            </div>
          </Col>
          <Col lg={4} md={12}>
            <LandingPageForm/>
          </Col>
        </Row>
      </div>
    </section>
  )
}

export default LogistikexpertenIntro
