import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import LogistikexpertenIntro from '../components/logistikexperten/LogistikexpertenIntro'
import FourFactors from '../components/logistikexperten/FourFactors'
import Seo from "../components/App/Seo"
import FooterLanding from "../components/App/FooterLanding"

const AboutUs = () => {
  return (
    <Layout>
      <Seo title={"Die Logistikexperten für Software im Lager und im Versand"}
           description={"LOGENTIS entwickelt seit fast 16 Jahren Logistiksoftware " +
           "für verschiedene Bereiche wie Versand, Warehouse Management und Materialflussoptimierung. "}
           image={"/images/og/logentis-main-og.png"}
      />
      <Navbar showNavigation={false}/>
      <LogistikexpertenIntro />
      <FourFactors />
      <FooterLanding/>
    </Layout>
  );
}


export default AboutUs;
