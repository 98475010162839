import React from "react"
import { Row, Col, Container } from "react-bootstrap"
import rocketIcon from "../../assets/images/logistikexperten/rocket.svg"
import planingIcon from "../../assets/images/logistikexperten/planing.svg"
import timeMoneyIcon from "../../assets/images/logistikexperten/timemoney.svg"
import htmlCodingIcon from "../../assets/images/logistikexperten/htmlcoding.svg"


const FourFactors = () => {
  return (
    <section className="services-area pt-5 pb-70 bg-f1f8fb">
      <Container>
        <div className="section-title">
          <h2>4 Faktoren für erfolgreiche Logistikprojekte</h2>
        </div>

        <Row>
          <Col sm={6} className={"p-3"}>
            <div className="single-services-item-box h-100">
              <div className="icon">
                <img src={planingIcon} alt="Prozessberatung Hand in Hand" style={{ width: "70px" }} />
              </div>
              <h3>
                Prozessberatung Hand in Hand
              </h3>
              <p>
                Wir beraten Sie bei Fragestellungen rund um Logistikprozesse. Wir
                überprüfen Kommissionierungsstrategien und analysieren Interaktionen mit
                angrenzenden ERP oder E-Commerce Systemen. Bei Fragestellungen rund um
                Themen wie Kapazitätsplanung, Lagerdimensionierung, Personalplanung oder
                ABC Zonierung arbeiten wir mit Spezialisten aus der Logistikberatung eng
                zusammen, um das bestmögliche Ergebnisse für unsere Kunden zu erzielen.
              </p>
            </div>
          </Col>

          <Col sm={6} className={"p-3"}>
            <div className="single-services-item-box h-100">
              <div className="icon">
                <img src={rocketIcon} alt="Immer die passende Hardware" style={{ width: "70px" }} />
              </div>
              <h3>
                Immer die passende Hardware
              </h3>
              <p>
                Neben der Softwareexpertise hat LOGENTIS durch viele Projekte
                Erfahrungen mit Logistikhardware in Bereichen wie Pick-By-Light Systeme,
                Barcodescanner, Thermotransferducker oder Industriewaagen. Nur die
                richtige Kombination und Anbindungsstrategie führen zu einem
                erfolgreichen Projekt. Speziell in der Logistik ist die Kombination aus
                ergonomischer, ausfallsicherer und robuster Hardware besonders wichtig
                und sollte immer Teil der Gesamtbetrachtung sein.
              </p>
            </div>
          </Col>

          <Col sm={6} className={"p-3"}>
            <div className="single-services-item-box h-100">
              <div className="icon">
                <img src={timeMoneyIcon} alt="Auszeichnung, Verpackung & Prozesszeiten" style={{ width: "70px" }} />
              </div>

              <h3>
                Auszeichnung, Verpackung & Prozesszeiten
              </h3>

              <p>
                Themengebiete wie das Auswählen und Bedrucken von stoßfesten Etiketten
                für die Lagerortauszeichnung gehören ebenso zu unseren Beratungsfeldern,
                wie auch das Aufzeigen von Möglichkeiten bei der Verpackungswahl. Wir
                haben Prozesszeiten, als eines der wichtigsten Kriterien in der
                Logistik, immer im Auge und optimieren diese in vielen Bereichen. Schnell und fehlerfrei eine Sendung auf den Weg zu bringen ist unser Ziel.
              </p>

            </div>
          </Col>

          <Col sm={6} className={"p-3"}>
            <div className="single-services-item-box h-100">
              <div className="icon">
                <img src={htmlCodingIcon} alt="Moderne cloud Architektur" style={{ width: "70px" }} />
              </div>

              <h3>
                Moderne Software
              </h3>

              <p>
                Gerade in der Logistik ist die Architektur der Software besonders wichtig, um eine
                maximale Abstraktion von der Hardware zu bekommen. Zu viele Projekte scheitern
                bei der ersten Hardware-Migration von Scanhardware oder anderen Geräten, da die
                Gerätesoftware oftmals sehr eng mit der Warehouse Management Software verknüpft.
                Gute Usability und Cloudfähigkeit sind weitere wichtige Punkte bei der Wahl
                der Supply Chain Management Software.
              </p>

            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default FourFactors
